import React, { useState, useEffect } from 'react';
import SearchBarLite from '../SearchBar/SearchBarLite';
import './index.css';

export default function Cover({ title, description, hotels }) {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = 'https://firebasestorage.googleapis.com/v0/b/zeylon-life-dev0.appspot.com/o/home%2F9_low_res.jpeg?alt=media&token=c66dc930-9908-4103-8ee8-d650fa235c05';
        img.onload = () => setIsImageLoaded(true);
    }, []);

    return (
        <div className='cover'>
            <div className={`cover-bg ${isImageLoaded ? 'loaded' : ''}`}></div>
            <div className='overlay'></div>
            <div className='cover-content'>
                <div className='cover-text'>
                    <h1 className="cover-title">{title}</h1>
                    <h3 className="cover-description">{description}</h3>
                </div>
                <div className="center">
                    <SearchBarLite hotels={hotels} fetchHotelsOnMount={false} />
                </div>
            </div>
        </div>
    );
}