import React, { useState, useEffect, useCallback } from "react";
import { AutoComplete, Button } from "antd";
import { SearchOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import "./index.css";
import locations from "../../data/locations.json";
import { BASE_URL } from '../../constants';

export default function SearchBarLite({ initialSearchTerm = '', onSearch, districtFilter, hotels: passedHotels, fetchHotelsOnMount = true }) {
  const [options, setOptions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState(initialSearchTerm);
  const [hotels, setHotels] = useState(passedHotels || []);
  const navigate = useNavigate();

  const fetchHotels = useCallback(async () => {
    try {
      const response = await fetch(`${BASE_URL}/hotels/limited`);
      const data = await response.json();
      const hotelNames = data.map(hotel => ({ name: hotel.name, id: hotel._id }));
      setHotels(hotelNames);
      setSuggestions([...hotelNames, ...locations.districts]);
    } catch (error) {
      console.error('Error fetching hotels:', error);
    }
  }, []);

  useEffect(() => {
    if (fetchHotelsOnMount) {
      fetchHotels();
    } else if (passedHotels) {
      const hotelNames = passedHotels.map(hotel => ({ name: hotel.name, id: hotel._id }));
      setHotels(hotelNames);
      setSuggestions([...hotelNames, ...locations.districts]);
    }
  }, [fetchHotelsOnMount, fetchHotels, passedHotels]);

  const handleSearch = useCallback((value) => {
    setSearchValue(value);
    const res = value ? suggestions.filter(location => location.name.toLowerCase().includes(value.toLowerCase())).map(location => ({
      value: location.name,
      label: location.name,
    })) : [];
    setOptions(res);
  }, [suggestions]);

  useEffect(() => {
    if (districtFilter === '') {
      setSearchValue('');
    } else {
      setSearchValue(districtFilter);
      handleSearch(districtFilter);
    }
  }, [districtFilter, handleSearch]);

  useEffect(() => {
    handleSearch(initialSearchTerm);
  }, [initialSearchTerm, handleSearch]);

  const handleSelect = (value) => {
    const matchedHotel = hotels.find(hotel => hotel.name.toLowerCase() === value.toLowerCase());
    if (matchedHotel) {
      navigate(`/p/${matchedHotel.id}`);
    } else {
      const matchedDistrict = locations.districts.find(d => d.name.toLowerCase() === value.toLowerCase());
      if (matchedDistrict) {
        navigate(`/search?query=${value}&district=${value}`);
      } else {
        navigate(`/search?query=${value}`);
      }
    }
    if (onSearch) {
      onSearch(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSelect(searchValue);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="search-bar">
          <div className="search-element location-wrapper">
            <AutoComplete
              bordered={false}
              className="location search-bar-dropdown"
              placeholder="Destination, City, Hotel, ..."
              style={{ border: 0 }}
              options={options}
              onSearch={handleSearch}
              onChange={setSearchValue}
              onSelect={handleSelect}
              value={searchValue}
              allowClear
            />
            <EnvironmentOutlined className="location-icon" />
          </div>
          <Button
            className="search-element search-button-round"
            type="default"
            shape="circle"
            icon={<SearchOutlined />}
            onClick={handleSubmit}
          />
          <Button
            type="default"
            size="large"
            className="search-button"
            onClick={handleSubmit}
          >
            Search
          </Button>
        </div>
      </form>
    </div>
  );
}