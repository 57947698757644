import React from 'react';

const CustomIcon = ({ name, onClick }) => {
  return (
    <div className="custom-icon-size" onClick={onClick} style={{ cursor: 'pointer' }}>
      {name}
    </div>
  );
};

export default CustomIcon;